<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="addEventDetails" class="w-100">
      <div class="row mb-2">
        <div class="col-12 text-right" v-if="isAllowed('accident-incident.event.edit') && isOwnVessel()">
          <button class="e-btn e-btn-outlined-red ml-2" type="button" v-if="toEdit" @click="cancel">Cancel</button>
          <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editValues">Edit Event Details</button>
          <button class="e-btn e-btn-blue ml-2" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" type="submit" v-if="toEdit" :disabled="hasChanges === false">Save</button>
        </div>
      </div>
      <table class="w-100">
        <tr>
          <td style="width: 25%">Event Details <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td>
            <vue-editor v-if="toEdit"  v-model="event.event_details" :editor-toolbar="toolbar" ></vue-editor>
            <div v-else>
              <div id="textArea" class="pre-wrap-txt textarea-display-card"  v-html="accidentIncidentReport.event.event_details || '' " />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="100%">
            <hr>
          </td>
        </tr>
        <tr>
          <td colspan="100%">
            <p class="float-right">
              <b>Note</b> : The structure and description of Event Code follow K-Line standard.
            </p>
            <h6 class="font-weight-bold">Event Code</h6>
          </td>
        </tr>
        <tr>
          <td>Event Code 1 <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td class="text-uppercase">
            <span></span>
            <select v-if="toEdit" name="" :disabled="!eventOneKeys.length" @change="modifyEventCode('event.event_one_id',$event)"  class="form-control form-control-sm mb-1 text-uppercase" v-model="event.event_one_id">
              <option :value="null">-- select event one --</option>
              <option v-for="event in eventOneKeys" :key="event.id" :value="event.id">
                {{event.code }})
                {{event.name}}
              </option>
            </select>
            <span v-else>
              {{accidentIncidentReport.event.eventOneCode ? accidentIncidentReport.event.eventOneCode + '_' : '' }}
              {{accidentIncidentReport.event.eventOne ? accidentIncidentReport.event.eventOne : ''}}
            </span>
          </td>
        </tr>
        <tr>
          <td class="event-pl-1">Event Code 2 <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td class="text-uppercase event-pl-1">
            <!--          {{eventTwoKeys}}-->
            <select v-if="toEdit" name="" @change="modifyEventCode('event.event_two_id',$event)" :disabled="!eventTwoKeys.length" class="form-control form-control-sm mb-1 text-uppercase" v-model="event.event_two_id">
              <option v-if="toEdit" :value="null">-- select event two --</option>
              <option v-for="event in eventTwoKeys" :key="event.id" :value="event.id">
                {{event.code }}_
                {{event.name}}
              </option>
            </select>
            <span v-else>
             {{accidentIncidentReport.event.eventTwoCode ? accidentIncidentReport.event.eventTwoCode + '_' : '' }}
             {{accidentIncidentReport.event.eventTwo ? accidentIncidentReport.event.eventTwo : ''}}
            </span>
          </td>
        </tr>
        <tr>
          <td class="event-pl-2">Event Code 3 <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td class="text-uppercase event-pl-2">
            <!--          {{eventThreeKeys}}-->
            <select v-if="toEdit" name="" @change="modifyEventCode('event.event_three_id',$event)" class="form-control form-control-sm mb-1 text-uppercase" v-model="event.event_three_id">
              <option :value="null">-- select event three --</option>
              <option v-for="event in eventThreeKeys" :disabled="!eventThreeKeys.length" :key="event.id" :value="event.id">
                {{event.code }}_
                {{event.name}}
              </option>
            </select>
            <span v-else>
             {{accidentIncidentReport.event.eventThreeCode ? accidentIncidentReport.event.eventThreeCode + '_' : '' }}
             {{accidentIncidentReport.event.eventThree ? accidentIncidentReport.event.eventThree : ''}}
            </span>
          </td>
        </tr>
        <tr>
          <td class="event-pl-3">Event Code 4 </td>
          <td class="text-uppercase event-pl-3">
            <select v-if="toEdit" name="" @change="modifyEventCode('event.event_four_id',$event)" class="form-control form-control-sm mb-1 text-uppercase" v-model="event.event_four_id" :disabled="event.event_three_id===50">
              <option :value="null">-- select event four --</option>
              <option v-for="event in eventFourKeys" :disabled="!eventFourKeys.length" :key="event.id" :value="event.id">
                {{event.code }}_
                {{event.name}}
              </option>
            </select>
            <span v-else>
             {{accidentIncidentReport.event.eventFourCode ? accidentIncidentReport.event.eventFourCode + '_' : '' }}
             {{accidentIncidentReport.event.eventFour ? accidentIncidentReport.event.eventFour : ''}}
            </span>
          </td>
        </tr>
        <tr>
          <td class="event-pl-4">Event Code 5</td>
          <td class="text-uppercase event-pl-4">
            <select v-if="toEdit" name=""  :disabled="!eventFiveKeys.length" id="" class="form-control form-control-sm mb-1 text-uppercase" v-model="event.event_five_id">
              <option :value="null">-- select event five --</option>
              <option v-for="event in eventFiveKeys" :key="event.id" :value="event.id" >
                {{event.code }}_
                {{event.name}}
              </option>
            </select>
            <span v-else>
             {{accidentIncidentReport.event.eventFiveCode ? accidentIncidentReport.event.eventFiveCode + '_' : '' }}
             {{accidentIncidentReport.event.eventFive ? accidentIncidentReport.event.eventFive : ''}}
            </span>
          </td>
        </tr>
<!--        <tr>-->
<!--          <td colspan="100%">-->
<!--            <hr>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td colspan="100%">-->
<!--            <h6 class="font-weight-bold">Machinery / Equipment</h6>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td >Equipment Manufacturer</td>-->
<!--          <td class="text-uppercase">-->
<!--            <textarea rows="2" class="form-control form-control-sm mb-1 pre-wrap-txt"-->
<!--                      v-model="event.equipment_manufacturer"-->
<!--                      v-if="toEdit"-->
<!--            ></textarea>-->
<!--            <span class="pre-wrap-txt" v-else>{{accidentIncidentReport.event.equipment_manufacturer}}</span>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td >Equipment Model</td>-->
<!--          <td class="text-uppercase">-->
<!--            <textarea rows="2" class="form-control form-control-sm mb-1 pre-wrap-txt"-->
<!--                      v-model="event.equipment_model"-->
<!--                      v-if="toEdit"-->
<!--            ></textarea>-->
<!--            <span class="pre-wrap-txt" v-else>{{accidentIncidentReport.event.equipment_model}}</span>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td >Related Part Numbers</td>-->
<!--          <td class="text-uppercase">-->
<!--            <textarea rows="2" class="form-control form-control-sm mb-1 pre-wrap-txt"-->
<!--                      v-model="event.part_number"-->
<!--                      v-if="toEdit"-->
<!--            ></textarea>-->
<!--            <span class="pre-wrap-txt" v-else>{{accidentIncidentReport.event.part_number}}</span>-->
<!--          </td>-->
<!--        </tr>-->
      </table>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {permissionMixin} from "@/mixins/permissionMixins";
import {DataService} from "@/services/DataService";

const { ReportService } = require('../../../services/ReportService')
const { AlertService } = require('../../../services/AlertService')

export default {
  name: 'Event',
  mixins:[permissionMixin],
  data () {
    return {
      event:{},
      reportId: this.$route.params.id,
      toEdit: false,
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ]
    }
  },
  methods: {
    ...mapActions(['getAccidentIncidentReport', 'getEventOneKeys', 'getEventTwoKeys', 'getEventThreeKeys', 'getEventFourKeys', 'getEventFiveKeys','updateAccidentIncidentEvent']),
    async addEventDetails () {
      if (await AlertService.confirmUpdateAlert()) {
        this.event.id=this.$route.params.id
        let response= await this.updateAccidentIncidentEvent(this.event)
        if(response){
          AlertService.successAlert('Updating Event Details', 'UPDATE')
          await this.getAccidentIncidentReport(this.reportId)
          this.toEdit = false
        }
      }
    },
    async cancel() {
      if (this.hasChanges === false) {
        this.toEdit = false
      } else {
        if (await AlertService.cancelAlert()) {
          this.toEdit = false
        }
      }
    },
    async editValues () {
      this.toEdit = true
      if (this.toEdit) {
        this.event = Object.assign({}, this.accidentIncidentReport.event)
        this.$nextTick(async ()=>{
          await this.getEventOneKeys()
          await this.getEventTwoKeys({event_one_id: this.event.event_one_id})
          await this.getEventThreeKeys({event_one_id: this.event.event_one_id, event_two_id: this.event.event_two_id})
          await this.getEventFourKeys({
            event_one_id: this.event.event_one_id,
            event_two_id: this.event.event_two_id,
            event_three_id: this.event.event_three_id
          })
          await this.getEventFiveKeys({ event_one_id: this.event.event_one_id, event_two_id: this.event.event_two_id, event_three_id: this.event.event_three_id })
        })
      }
    },

    modifyEventCode(key = '', evt) {
      switch (key){
        case 'event.event_one_id':
         this.event.event_one_id = evt.target.value === '' ? null : evt.target.value;
         this.event.event_two_id = null
         this.event.event_three_id = null
         this.event.event_four_id = null
         this.event.event_five_id = null
         this.getEventTwoKeys({event_one_id: this.event.event_one_id})
         break;

        case 'event.event_two_id' :
          this.event.event_two_id = evt.target.value === '' ? null : evt.target.value;
          this.event.event_three_id = null
          this.event.event_four_id = null
          this.event.event_five_id = null
          this.getEventThreeKeys({event_one_id: this.event.event_one_id, event_two_id: this.event.event_two_id})
          break;

        case 'event.event_three_id':
          this.event.event_three_id = evt.target.value === '' ? null : evt.target.value;
          this.event.event_four_id = null
          this.event.event_five_id = null
          this.getEventFourKeys({
            event_one_id: this.event.event_one_id,
            event_two_id: this.event.event_two_id,
            event_three_id: this.event.event_three_id
          })
          if (this.event.event_three_id === 50 || this.eventFourKeys.length > 0) {
            this.getEventFiveKeys({
              event_one_id: this.event.event_one_id,
              event_two_id: this.event.event_two_id,
              event_three_id: this.event.event_three_id
            })
            this.event.event_four_id = null;
          }
          break;
        case 'event.event_four_id':
          this.event.event_four_id = evt.target.value === '' ? null : evt.target.value;
          this.event.event_five_id = null
          this.getEventFiveKeys({ event_one_id: this.event.event_one_id, event_two_id: this.event.event_two_id, event_three_id: this.event.event_three_id })
          break;
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges()) {
        next();
      } else {
        next(false);
      }

    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['eventOneKeys', 'eventTwoKeys', 'eventThreeKeys', 'eventFourKeys', 'eventFiveKeys', 'accidentIncidentReport']),
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      let editedData = {
        event_one_id   : this.event.event_one_id !== null ? parseInt(this.event.event_one_id) : null,
        event_two_id   : this.event.event_two_id !== null ? parseInt(this.event.event_two_id) : null,
        event_three_id : this.event.event_three_id !== null ? parseInt(this.event.event_three_id) : null,
        event_four_id  : this.event.event_four_id !== null ? parseInt(this.event.event_four_id) : null,
        event_five_id  : this.event.event_five_id !== null ? parseInt(this.event.event_five_id) : null,
      };

      if (this.accidentIncidentReport.event.event_details === null) {
        editedData.event_details = this.event.event_details === '' ? null : this.event.event_details
      } else {
        editedData.event_details = this.event.event_details
      }

      return DataService.checkIfChanged(this.accidentIncidentReport.event, editedData);
    }
  },
}
</script>

<style scoped>
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
.event-pl-1 {
  padding-left: 1rem;
}
.event-pl-2 {
  padding-left: 2rem;
}
.event-pl-3 {
  padding-left: 3rem;
}
.event-pl-4 {
  padding-left: 4rem;
}
</style>
